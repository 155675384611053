import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
// import moment from 'moment'
import { browserHistory } from 'browserHistory'

import Search from '../../../components/ui/controls/search'
import Preview from '../../../components/assets/preview'
import Button from '../../../components/ui/controls/button'
import Label from '../../../components/ui/label'


import { handleRequestFailed } from '../../../core/services/errorhandling'
import { search, searchTv4 } from '../../../apis/content'

import { getSwedishVersion } from '../../../components/program/utils'


import InternalScheduleStore from '../store'

import '../../../components/listItems/standard.css'
import './search.css'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

export default class DiscoveryProgramSearch extends Component {

	constructor(props) {
		super(props);

		this.renderResult = this.renderResult.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.hideSearch = this.hideSearch.bind(this);
		this.checkClick = this.checkClick.bind(this);
		this.debouncedSearch = debounce(this.search, 300);
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.reset = this.reset.bind(this);
		this.handleProgramClick = this.handleProgramClick.bind(this);

		this.latestRequestTime = 0;
		this.searchButton = null;
		this.searchContainer = null;
		this.searchInput = null;

		const appState = InternalScheduleStore.getState();
		this.apiKey = appState && appState.filters && appState.filters.apikey || "";

		this.state = {
			results: null,
			searchHiddenOnMobile: true,
			isLoading: false,
		};
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("click", this.checkClick);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.checkClick);
	}

	checkClick(e) {
		let node = e.target;

		if (node !== this.searchButton && !isNodeChildOf(node, this.searchContainer)) {
			this.hideSearch();
		}
	}

	hideSearch() {
		this.setState({
			results: null,
			searchHiddenOnMobile: true,
			isLoading: false,
		});
	}

	search(searchText) {
		if (!this.apiKey) {
			console.warn("No apikey found :(");
		}

		const filters = {
			searchText,
			pageSize: 50,
			apikey: this.apiKey,
			// programType: "single|series|sport",
			// validFromStart: moment().format("YYYY-MM-DD"),
			// validFromEnd: moment().add(10, "years").format("YYYY-MM-DD"),
			// language: "sv"
		};

		this.setState({ isLoading: true });
		const requestTime = this.latestRequestTime = Date.now();

		// #TempHackForTv4InternalSchedule
		const isAuthVersion = __BUILD_PRODUCT__ === "internal-schedule-auth";
		const searchMethod = isAuthVersion ? search : searchTv4;
		searchMethod(filters)
			.then(response => {
				if (this.latestRequestTime > requestTime) {
					console.log("Ignoring result with %s items since there have been newer requests.", response.numberOfItems);
					return;
				}

				this.setState({
					results: response.items,
					isLoading: false,
				});
			}, error => {
				this.setState({ isLoading: false });
				handleRequestFailed(error);
			})
			.catch(error => {
				this.setState({ isLoading: false });
				handleRequestFailed(error);
			});
	}

	reset() {
		this.latestRequestTime = Date.now();
		this.isLoading = false;
		this.debouncedSearch.cancel();
		this.setState({ results: null });
	}

	handleChange(e) {
		const searchText = e.target.value;
		if (!searchText || searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST) {
			this.reset();
			return;
		}

		this.debouncedSearch(searchText);
	}


	handleButtonClick(e) {
		this.setState({ searchHiddenOnMobile: false });
		setTimeout(() => this.searchInput.focus(), 0);
	}

	handleProgramClick(item, e) {
		const { id, type } = item;
		let url;
		switch(type) {
			case "series":
				url = `/series/${id}`;
				break;
			case "family":
				url = `/family/${id}`;
				break;
			default:
				url = `/program/${id}`;
		}

		// #TempHackForTv4InternalSchedule
		const isAuthVersion = __BUILD_PRODUCT__ === "internal-schedule-auth";
		if (!isAuthVersion) {
			url += "?site=tv4play";
		}

		if(this.props.replaceHistoryOnSelect) {
			browserHistory.replace(url);
		}
		else {
			browserHistory.push(url);
		}
		this.reset();
	}

	renderResult() {
		const { results, isLoading } = this.state;

		if (!isLoading && results && results.length) {
			return results.map(item => {
				const mainAsset = item.assets.find(a => a.type === "image" && a.category === "main");
				const mainAssetGuid = mainAsset && mainAsset.id;
				const title = getSwedishVersion(item.versions).title || item.originalTitle;
				const family = item.type === "family" ? <Label status="true">Programfamilj</Label> : null;

				return (
					<div
						key={item.id}
						className="c6-discovery-search-item flex"
						onClick={e => this.handleProgramClick(item, e)}
					>
						<Preview guid={mainAssetGuid} size="smallStarAsset" />
						<div className="c6-content">
							<h1>{title}</h1>{family}
						</div>
					</div>
				);
			});
		}

		let message, status;
		if (isLoading) {
			message = "Söker...";
			status = " loading";
		} else if (results && !results.length) {
			message = "Inga träffar";
			status = " no-results";
		}

		return message && (
			<div className={`c6-discovery-search-item flex ${status}`}>
				<div className="c6-content">
					<h1>{message}</h1>
				</div>
			</div>
		);
	}

	render() {
		const { searchHiddenOnMobile, searchInputFocused } = this.state;

		let wrapperClassNames = "c6-discovery-search-wrapper";
		if (this.props.extraRightMarginForAuthVersion) wrapperClassNames += " extra-margin-right-search-button";

		let containerClassNames = "c6-discovery-search-container";
		if (searchHiddenOnMobile) containerClassNames += " hidden-on-mobile";
		if (searchInputFocused) containerClassNames += " focused";

		return (
			<div className={wrapperClassNames}>
				<Button
					buttonRef={ref => { if (ref) this.searchButton = ref }}
					noBackground
					type="search"
					onClick={this.handleButtonClick}
				/>
				<div className={containerClassNames} ref={ref => { if (ref) this.searchContainer = ref }}>
					<Button
						noBackground
						type="arrow_back"
						onClick={this.hideSearch}
					/>
					<Search
						autocomplete="off"
						onChange={this.handleChange}
						inputRef={ref => { if (ref) this.searchInput = ref }}
						onFocus={() => this.setState({ searchInputFocused: true }) }
						onBlur={() => this.setState({ searchInputFocused: false }) }
						placeholder="Programsök"
					/>
					{this.renderResult()}
				</div>
			</div>
		);
	}
}

// DiscoveryProgramSearch.propTypes = {

// };

// DiscoveryProgramSearch.defaultProps = {

// };


function isNodeChildOf(node, container) {
	if (!node) return false;
	if (node.parentNode === container) return true;

	return isNodeChildOf(node.parentNode, container);
}