import React from 'react'
import Moment from 'moment'
import 'moment/locale/sv'
import { browserHistory } from 'browserHistory'
// import keydown from 'react-keydown'

import App from '../../../components/app'

import Header from './header'
import Family from '../../../components/family/family'

import { getProgramFamilyLink, getProgramMetadata } from '../../../components/program/utils'
import { refreshPageIfLostVPNConnection } from '../shared/refreshPageIfLostVPNConnection'

import Actions from '../actions'
import Store from '../store'

import './app.css'

// @keydown
export default class ProgramApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onSelectSeason = this.onSelectSeason.bind(this);

		this.list = null;
		this.familyId = this.props.params.familyId;
		this.seasonId = this.props.params.seasonId;
		this.id = this.props.params.id;

		this.state = {
			...Store.getState(),
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		this.fetchData();

		refreshPageIfLostVPNConnection();
	}

	UNSAFE_componentWillReceiveProps({ params }) {
		const { data } = this.state.family;
		const program = data.links && data.links.find(l => l.rel === "main");
		const currentSeason = program && program.links && program.links.find(l => l.links.length); // The current season is the one with populated child links /HEL

		// Fetch fresh data if the family or season has changed
		const familyChanged = params.familyId && params.familyId !== this.familyId;
		const seasonChanged = params.seasonId && params.seasonId !== (this.seasonId || currentSeason.id);
		// const programChanged = !familyChanged && !seasonChanged && params.id !== this.id;

		this.familyId = params.familyId;
		this.seasonId = params.seasonId;
		this.id = params.id;

		if(familyChanged || seasonChanged) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	fetchData() {
		const { apikey } = this.state.filters;

		Actions.fetchFamily(this.familyId, { apikey, seasonId: this.seasonId });
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	onSelectSeason(e, item) {
		const seasonId = item && item.props && item.props.value || e.target.value;
		browserHistory.replace(getProgramFamilyLink(this.familyId, seasonId));
	}

	render() {
		const { isLoading, family, schedules } = this.state;
		const { data, included } = family;
		if(!data) {
			return null;
		}

		let seasons = [], currentSeason = {}, episodes = null, programId = this.id, seasonId = null;
		const mainPrograms = data.links ? data.links.filter(l => l.rel === "main") : [];
		// const isSeriesProgramFamily = data.category !== "Movie";
		const isSeriesProgramFamily = mainPrograms.length === 1 && mainPrograms[0].type === "series";

		if(isSeriesProgramFamily) {
			const { links = [] } = mainPrograms[0] || {};

			seasons = links.filter(l => l.type === "season");
			currentSeason = links.find(l => l.links.length); // The current season is the one with populated child links /HEL
			episodes = getActiveOrPastEpisodes(currentSeason, included); // Display only past + one upcoming linear episodes on currently airing seasons /EDI

			seasonId = currentSeason && currentSeason.id;
			programId = this.id || episodes && episodes.length && episodes[0].id || null;
		}
		else {
			programId = this.id || mainPrograms && mainPrograms.length && mainPrograms[0].id || null;
		}

		const selected = {
			familyId: this.familyId,
			seasonId,
			programId,
		}

	    return (
			<App name={`c6-internal-schedule c6-internal-schedule-program`} layout="grid" isLoading={isLoading}>
				<Header
					content={included}
					selected={selected}

					seasons={seasons}
					handleSelectSeason={this.onSelectSeason}
					goBackInsteadOfLink={!!schedules.length} />
				<Family
					isLoading={isLoading}
					content={included}
					selected={selected}

					family={data}
					items={episodes || mainPrograms} />
			</App>
		);
	}
}

// HELPERS
function getActiveOrPastEpisodes(currentSeason, metadata) {
	let episodes = currentSeason && currentSeason.links && currentSeason.links.filter(l => l.type === "episode");

	const now = Moment();
	const season = getProgramMetadata(currentSeason.id, metadata);
	const seasonLinearRights = season && season.rights && season.rights.find(r => r.type === "linear");
	const currentlyActiveSeason = seasonLinearRights && now.isBetween(seasonLinearRights.validFrom, seasonLinearRights.validUntil);

	if(currentlyActiveSeason) {
		episodes = filterPastAndOneUpcomingEpisodes(episodes, metadata, now); // Display past episodes + one upcoming
		addBroadcastTextToEpisodeMetadata(episodes[0], metadata); // Add a broadcast text to the upcoming episode

		return episodes;
	}

	return episodes;
}

function filterPastAndOneUpcomingEpisodes(episodes, metadata, now) {
	const latestBroadcastIndex = episodes.findIndex(e => {
		const { rights = [], episodeInfo } = getProgramMetadata(e.id, metadata);
		return rights.findIndex(r => r.type === "linear" && now.subtract(episodeInfo.minutes || 60, "minutes").isAfter(r.validFrom)) >= 0;
	});

	return episodes.slice((latestBroadcastIndex || 1) - 1); // Start returning episodes from the previous item
}

function addBroadcastTextToEpisodeMetadata(upcomingEpisode, metadata) {
		if(!upcomingEpisode) {
			return false;
		}

		const upcomingEpisodeMetadata = getProgramMetadata(upcomingEpisode.id, metadata);
		const { rights = [] } = upcomingEpisodeMetadata;
		const linearRight = rights.find(r => r.type === "linear");

		if(linearRight && linearRight.validFrom) {

			const broadcastText = Moment(linearRight.validFrom).locale("sv").calendar(null, {
				sameDay: '[Idag klockan] HH:mm',
				nextDay: '[Imorgon klockan] HH:mm',
				nextWeek: '[På] dddd [klockan] HH:mm',
				sameElse: '[På] dddd [klockan] HH:mm'
			});

			upcomingEpisodeMetadata._upcomingBroadcast = broadcastText;
		}
}