import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import {Filter, Left, Right} from '../../../components/filter'
import DropDown from '../../../components/ui/controls/dropdown'

import ProgramSearch from '../shared/search'
import { getProgramMetadata, getSwedishVersion } from '../../../components/program/utils'
import { logoutUser } from '../../../core/services/auth'

import appConfig from 'config'

export default ({
	content,
	selected,
	seasons = [],
	handleSelectSeason,
	goBackInsteadOfLink,
}) => {

	// We need to go back instead of doing a normal navigation to allow for
	// react-router-scroll to restore the scroll position of the library list.
	function handleClick(e) {
		if(goBackInsteadOfLink) {
			e.preventDefault();
			browserHistory.goBack();
		}
	}
	const { familyId, seasonId } = selected;

	const metadata = getProgramMetadata(familyId, content);
	const title = metadata && getSwedishVersion(metadata.versions).title || "";

	const isAuthVersion = __BUILD_PRODUCT__ === "internal-schedule-auth";

	return (
		<Filter>
			<Left>
				<Link
					to={"/schedule"}
					onClick={handleClick}
					className="c6 c6-button c6-text icon-chevron_left">
					<span>Till tablån</span>
				</Link>
				{getMetadataEditorButton(familyId)}
				<div>
					<h1>
						{title}
						<DropDown
							name="seasons"
							onChange={handleSelectSeason}
							states={getSeasons(seasons, content)}
							currentState={seasonId} />
					</h1>
				</div>
			</Left>
			{appConfig.features && appConfig.features.internalScheduleSearch && (
				<ProgramSearch extraRightMarginForAuthVersion={isAuthVersion} replaceHistoryOnSelect={true} />
			)}
			{isAuthVersion && (
				<span title="Log out" className="icon-cancel logout" onClick={logoutUser}></span>
			)}
		</Filter>
	);
};

// HELPER
function getSeasons(seriesSeasons, metadata) {
	return seriesSeasons.map(s => {

		const season = getProgramMetadata(s.id, metadata);
		const  { versions = [], seasonInfo } = season;
		const seasonTitle = getSwedishVersion(versions).title;
		const  { productionYear, seasonNumber } = seasonInfo;

		return {
			key: season.id,
			text: seasonTitle || (productionYear || seasonNumber ? `SÄSONG ${productionYear || seasonNumber} SAKNAR Season Label` : "?"),
		};
	});
}

function getMetadataEditorButton(familyId) {
	let metadataEditorUrl = appConfig.features && appConfig.features.metadataUrl;
	if(!metadataEditorUrl) {
		return null;
	}

	return (
		<Link
			target="_blank"
			to={`${metadataEditorUrl}families/${familyId}`}
			className="c6 c6-button c6-text icon-create icon-smaller">
			<span>Editera i Comet</span>
		</Link>
	);
}