import React from 'react'
import PropTypes from 'prop-types'

import Main from '../ui/main'

import { getProgramMetadata, getSeriesMetadata, getSwedishVersion } from '../program/utils'

import Metadata from '../program/metadata'

import Content from './content'
import ContentList from './contentList'

// import '../program/program.css'
import '../program/program.css'
import './family.css'

export default class Family extends React.PureComponent {

	static propTypes = {
		content: PropTypes.array,
		family: PropTypes.object,
		items: PropTypes.array,
		selected: PropTypes.object,
		isLoading: PropTypes.bool,
	}

	render() {
		const {
			content,
			family,
			items,
			selected,
			isLoading,
		} = this.props;

		if(isLoading) {
			return null;
		}

		const familyMetadata = getProgramMetadata(family.id, content);
		const familyTitle = familyMetadata && getSwedishVersion(familyMetadata.versions).title || "";

		const selectedItemMetadata = getProgramMetadata(selected.programId, content);
		const selectedItemSeriesMetadata = getSeriesMetadata(selectedItemMetadata, content);
		const detailHeading = getDetailHeading(selected.programId, items, content, selectedItemMetadata, selectedItemSeriesMetadata);
		const programMetadata = selectedItemMetadata && selectedItemMetadata.type === "single"
			? <Metadata program={selectedItemMetadata} />
			: null;

		return (
			<div className="c6-program c6-program-family">
				<Main>
					<Content type="family" metadata={familyMetadata} />
					<ContentList
						listItems={items}
						content={content}
						selected={selected} />
				</Main>
				<Main>
					<section>
						<Content
							heading={detailHeading}
							type="detail"
							metadata={selectedItemMetadata}
							seriesMetadata={selectedItemSeriesMetadata} />
						{programMetadata}
					</section>
				</Main>
				<Main>
					<ContentList
						heading="Uppföljare (Spin-off)"
						relation="spinOff"
						links={family.links}
						content={content} />

					<ContentList
						heading={`Relaterat till ${familyTitle}`}
						relation="related"
						links={family.links}
						content={content} />

				</Main>
			</div>
	    );
  	}
}

// HELPERS
function getDetailHeading(id, items, content, metadata, seriesMetadata) {
	if(!metadata) {
		return null;
	}

	if(metadata.type === "single" || metadata.type === "series") {
		return metadata.versions ? getSwedishVersion(metadata.versions).title : null;
	}

	const selectedItemParentEpisode = items.find(e => e.links.find(l => l.id === id));

	// If this is a linked episode (like a postEpisode) we need to fetch
	// the series title for the series the episode belongs to
	if(selectedItemParentEpisode) {
		const selectedItemParentEpisodeMetadata = getProgramMetadata(selectedItemParentEpisode.id, content);
		return "Del " + (selectedItemParentEpisodeMetadata.episodeInfo.episodeNumber || "?") + ": " + getSwedishVersion(seriesMetadata.versions).title;
	}

	return "Del " + (metadata.episodeInfo.episodeNumber || "?");
}