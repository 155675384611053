import React from 'react'
import ResponsiveImage from '../../../components/assets/responsiveImage'

import styles from './image.css'

export default class Image extends React.PureComponent {
	constructor(props) {
		super(props);
		this.id = this.props.params.id || null;
	}

	render() {
		return <div className="internal-schedule-image-preview"><ResponsiveImage id={this.id} format="fit" /></div>;
	}
}