import React from 'react'

import { getProgramMetadata, getSeriesMetadata } from '../program/utils'

import Content from './content'

const ContentList = ({ heading, listItems, relation, links = [], content, selected }) => {

	const items = listItems || links && links.filter(l => l.rel === relation);
	if(!items.length) {
		return null;
	}

	const type = ["preEpisode", "postEpisode"].includes(relation) ? "related" : null;
	const className = `count-${items.length > 3 ? "4-or-more" : items.length} ${type || ""}`;

	return (
		<div className={`content-list content-list-${relation || "episodes"}`}>
			{ heading ? <h2>{heading}</h2> : null }
			<div className={className}>
				{
					items.map(item => {
						const { id, rel, links } = item;
						const itemMetadata = getProgramMetadata(id, content);

						// To get the series image + title for the pre/post-episode series the episode belongs to +
						const seriesMetadata = getSeriesMetadata(itemMetadata, content);

						return (
								<Content
									key={id}
									rel={rel}
									selected={selected}
									metadata={itemMetadata}
									seriesMetadata={seriesMetadata}
								>

									<ContentList
										heading="Före avsnittet"
										relation="preEpisode"
										links={links}
										content={content}
										selected={selected}
									/>

									<ContentList
										heading="Efter avsnittet"
										relation="postEpisode"
										links={links}
										content={content}
										selected={selected}
									/>

								</Content>
							);
					})
				}
			</div>
		</div>
	);
};

export default ContentList;