import React from 'react'
import { logoutUser } from '../../core/services/auth'

import './unauthorized.css'

export default function Unauthorized() {
	return (
        <div className="c6-internal-schedule-unauthorized">
            <div>
                <p>
                    This account does not have access to the Linear and VOD schedules presentation.
                    <br/>Please contact <a className="c6-link" href="mailto:support@junefirst.tv">support@junefirst.tv</a> to request access.
                </p>
                <a
                    className="c6-link icon-cancel"
                    onClick={() => logoutUser()}
                >
                    Log out
                </a>
            </div>
        </div>
    );
}