import React 				 from 'react'
import { Route, IndexRoute } from 'react-router'

import Module        		 from './app'
import Schedule       		 from './schedule/app'
import Program       		 from './program/app'
import Family       		 from './family/app'
import Image                 from './program/image'

export default class InternalScheduleModule {
	static routes() {
		return (
			<Route component={Module} module="internal-schedule">
				<Route path="schedule" component={Schedule} />
				<Route path="schedule/today(/:channels)" component={Schedule} />
				<Route path="schedule/:date(/:channels)" component={Schedule} />

				<Route path="family/:familyId/program/:id" component={Family} />
				<Route path="family/:familyId(/:seasonId)(/:id)" component={Family} />
				<Route path="series/:seriesId/season/:seasonId/episode/:id" component={Program} />
				<Route path="series/:seriesId/season/:seasonId" component={Program} />
				<Route path="series/:seriesId" component={Program} />
				<Route path="season/:seasonId/episode/:id" component={Program} />
				<Route path="program/:id" component={Program} />
				<Route path="image/:id" component={Image} />
			</Route>
		);
	}
}
