import React from 'react'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import { Swedish } from "flatpickr/dist/l10n/sv.js"
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'
import '../../../components/ui/controls/pickers/picker.css'
import { keydownScoped } from 'react-keydown'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Moment from 'moment'

import {Filter, Left, Right} from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Pick from '../../../components/ui/controls/pick'

import Actions from '../actions'
import ProgramSearch from '../shared/search'
import { logoutUser } from '../../../core/services/auth'

import './header.css'

import appConfig from 'config'

const SHOW_CHANNEL_PICKERS_DESKTOP_KEY = "c6-showChannelPickersDesktop";

export default class Header extends React.Component {

	static propTypes = {
		title: PropTypes.string,
		date: PropTypes.string,
		channels: PropTypes.array,
		selectedChannels: PropTypes.string,
	}

	constructor(props) {
		super(props);
		this.handleNavPrev = this.handleNavPrev.bind(this);
		this.handleNavNext = this.handleNavNext.bind(this);
		this.handleNavToday = this.handleNavToday.bind(this);

		this.state = {
			drawerOpen: false,
			showChannelPickersDesktop: JSON.parse(localStorage.getItem(SHOW_CHANNEL_PICKERS_DESKTOP_KEY)) ?? false,
		};
	}

	@keydownScoped("left")
	handleNavPrev() {
		const { selectedChannels, date } = this.props;
		Actions.navPrev(date, selectedChannels);
	}

	@keydownScoped("right")
	handleNavNext() {
		const { selectedChannels, date } = this.props;
		Actions.navNext(date, selectedChannels);
	}

	@keydownScoped("ctrl+t")
	handleNavToday() {
		const { selectedChannels } = this.props;
		Actions.navToday(selectedChannels);
	}

	handleNavDate = (date) => {
		const { selectedChannels } = this.props;
		Actions.navDate(date[0], selectedChannels);
	}

	handleChannelPick = (e, pickedChannels) => {
		const channel = pickedChannels || e.target.value;
		const {selectedChannels, date} = this.props;

		if(channel) {
			if (!e.target.checked || e.target.checked && e.target.value === "-") {
				Actions.unselectChannel(channel, selectedChannels, date);
			}
			else {
				Actions.selectChannel(channel, selectedChannels, date);
			}
		}
	}

	renderDrawerItems = () => {
		const { channels, selectedChannels } = this.props;
		const tv4Channels 			= channels.filter(c => c.group === "tv4");
		const cmoreChannels 		= channels.filter(c => c.group === "cmore");
		const mtvChannels 			= channels.filter(c => c.group === "mtv");
		const cmoreFinlandChannels 	= channels.filter(c => c.group === "cmore-fi");

		const renderGroupItems = channels => channels.map(channel => {
			const selected = selectedChannels.split(",").includes(channel.key);
			return (
				<ListItem key={channel.key}>
					<ListItemButton
						onClick={() => this.handleChannelPick({ target: { checked: !selected }}, channel.key)}
					>
						{selected && <ListItemIcon><span className="icon-check"></span></ListItemIcon>}
						{channel.text}
					</ListItemButton>
				</ListItem>
			);
		});

		const groups = [];
		if (!appConfig.features.hideTV4Channels) {
			groups.push(renderGroupItems(tv4Channels));
			groups.push(<Divider key="divider-tv4" />);
		}
		if (!appConfig.features.hideCMoreChannels) {
			groups.push(renderGroupItems(cmoreChannels));
			groups.push(<Divider key="divider-cmore" />);
		}
		if (!appConfig.features.hideMTVChannels) {
			groups.push(renderGroupItems(mtvChannels));
			groups.push(<Divider key="divider-mtv" />);
		}
		if (!appConfig.features.hideFinnishChannels) {
			groups.push(renderGroupItems(cmoreFinlandChannels));
		}

		return groups;
	}

	renderChannelPicker(states, currentState) {
		return (
			<div>
				<Pick
					pickAll={true}
					pickAllLabels={["Alla", "Inga"]}
					onChange={this.handleChannelPick}
					states={states}
					currentState={currentState}
				/>
			</div>
		);
	}

	toggleShowChannelPickersDesktop = () => {
		const showChannelPickersDesktop = !this.state.showChannelPickersDesktop;
		localStorage.setItem(SHOW_CHANNEL_PICKERS_DESKTOP_KEY, showChannelPickersDesktop);
		this.setState({ showChannelPickersDesktop });
	}

	render() {
		const { channels, selectedChannels, date } = this.props;
		const tv4Channels 			= channels.filter(c => c.group === "tv4");
		const cmoreChannels 		= channels.filter(c => c.group === "cmore");
		const mtvChannels 			= channels.filter(c => c.group === "mtv");
		const cmoreFinlandChannels 	= channels.filter(c => c.group === "cmore-fi");
		const currentState = selectedChannels?.length ? selectedChannels.split(",") : [];

		const isAuthVersion = __BUILD_PRODUCT__ === "internal-schedule-auth";

		return (
			<Filter>
				<Left>
					<Button
						type=""
						title="Idag"
						hoverTitle="Hoppa till dagens datum [CTRL-T]"
						onClick={this.handleNavToday} />
					<Button
						type="prev"
						hoverTitle="Gå till föregående dag [PIL VÄNSTER]"
						onClick={this.handleNavPrev} />
					<Button
						type="next"
						hoverTitle="Gå till nästa dag [PIL HÖGER]"
						onClick={this.handleNavNext} />
					{getWeekLabel(date)}
					<Flatpickr
						options={{
							altInput: true,
							// altInputClass: "icon-schedule",
							altFormat: "D j M Y",
							locale:	Swedish,
						}}
						value={date}
						onChange={this.handleNavDate}
						onReady={(_, __, fp) => fp.calendarContainer?.classList.add("c6-internal-schedule-datetimepicker")}
					/>
					<div className={`channel-pickers-desktop ${this.state.showChannelPickersDesktop ? "" : "hide"}`}>
						{!appConfig.features.hideTV4Channels && this.renderChannelPicker(tv4Channels, currentState)}
						{!appConfig.features.hideCMoreChannels && this.renderChannelPicker(cmoreChannels, currentState)}
						{!appConfig.features.hideMTVChannels && this.renderChannelPicker(mtvChannels, currentState)}
						{!appConfig.features.hideFinnishChannels && this.renderChannelPicker(cmoreFinlandChannels, currentState)}
					</div>
					<div className="channel-pickers-desktop-toggler">
						<a
							className={`c6-link icon-expand_${this.state.showChannelPickersDesktop ? "less" : "more"}`}
							onClick={this.toggleShowChannelPickersDesktop}
						>
							{this.state.showChannelPickersDesktop ? "Hide" : "Show"} channel options
						</a>
					</div>
					<Button
						type="menu"
						title="Channels"
						onClick={() => this.setState({ drawerOpen: true })}
						className="channels-drawer-btn"
					/>
					<Drawer
						anchor="left"
						variant="temporary"
						className="c6-drawer-root"
						onClose={() => this.setState({ drawerOpen: false })}
						open={this.state.drawerOpen}
					>
						<List>
							{this.renderDrawerItems()}
						</List>
					</Drawer>
				</Left>
				{appConfig.features && appConfig.features.internalScheduleSearch && (
					<ProgramSearch extraRightMarginForAuthVersion={isAuthVersion} />
				)}
				{isAuthVersion && (
					<span title="Log out" className="icon-cancel logout" onClick={logoutUser}></span>
				)}
			</Filter>
		);
  	}
}

// HELPERS
// labelGenerator={generateLabel}
function generateLabel(label, value, group) {
	const labelText = group === "cmore" ? null : label; // No label text for C More channels
	return <span className={`channel-logo channel-${value}`}>{labelText}</span>;
}

function getWeekLabel(date) {
	const weekNumber = Moment(date).isoWeek();
	return <span className={"weeknumber"}>V{weekNumber}</span>;
}

// Function that parses dates that are typed/pasted in the text input
// function parseDate(dateString, format) {
// 	const dateMoment = Moment(dateString, format);
// 	return dateMoment.isValid() ? dateMoment.toDate() : "";
// };