import React from 'react'
import { browserHistory } from 'browserHistory'

import ResponsiveImage from '../assets/responsiveImage'

import { getProgramLink, getProgramFamilyLink, getSwedishVersion, getSynopsis } from '../program/utils'

export default ({ type, metadata, seriesMetadata, rel, selected = {}, heading, children }) => {
	if(!metadata) {
		return null;
	}

	const className = getClassName(metadata, selected.programId, type);

	return (
		<article
			key={metadata.id}
			className={className}
			onClick={handleSelectEpisode.bind(this, metadata, rel, selected)}
		>
			{getTopHeader(metadata, rel)}
			{getMainResponsiveImage(metadata, seriesMetadata)}
			{getProgramPresentation(heading, rel, type, metadata, seriesMetadata)}
			{children}
		</article>
	);
};

// HELPERS
function getClassName(metadata, selectedId, type = "") {
	const selected = metadata.id === selectedId ? "sel" : "";
	const programType = metadata.type ? `type-${metadata.type}` : "";
	const hasUpcomingBroadcast = metadata._upcomingBroadcast ? "upcoming" : "";

	return `${selected} ${type} ${programType} ${hasUpcomingBroadcast}`;
}

function getTopHeader(metadata, rel) {
	const { episodeInfo, type } = metadata;

	if(episodeInfo && rel === "child") {
		return <header><h2><span>Del</span> {episodeInfo.episodeNumber}</h2>{getSynopsis(metadata, true)}</header>;
	}
	else if(rel === "related" || rel === "spinOff" || (["single", "series"].includes(type) && rel === "main")) {
		const relatedTitle = getSwedishVersion(metadata.versions).title;
		return <header><h2>{relatedTitle}</h2></header>;
	}

	return null;
}

function handleSelectEpisode(metadata, rel, selected, e) {
	e.stopPropagation();

	const { id, type } = metadata;
	const { familyId, seasonId } = selected;

	// Naviage to series or program page if this is a related show
	if(rel === "related") {
		const link = type === "series" ? getProgramLink(id) : getProgramLink(null, null, id);
		browserHistory.push(link);
	}
	// Otherwise stay on family page
	else if(rel){
		browserHistory.replace(getProgramFamilyLink(familyId, seasonId, id));
	}
}

function getRelatedTitle(metadata, rel) {
	if(rel === "child" || rel === "related") {
		return null;
	}

	const seriesSwedishVersion = metadata && metadata.versions && getSwedishVersion(metadata.versions);

	return seriesSwedishVersion ? <h3>{seriesSwedishVersion.title}</h3> : null;
}

function getMainResponsiveImage(program, series) {
	const programImage = program && program.assets && program.assets.filter(a => a.category === "main" && a.type === "image");

	// Fallback to series image if missing episode or available
	let image = programImage && programImage.length ? programImage[0] : null;
	if(!image && series) {
		const seriesImage = series && series.assets && series.assets.filter(a => a.category === "main" && a.type === "image");
		image = seriesImage && seriesImage.length ? seriesImage[0] : null;
	}

	return image
		? <ResponsiveImage id={image.id} sizes="(min-width: 985px) 33vw, (min-width: 665px) 50vw, 100vw" />
		: <div />;
}

function getProgramPresentation(heading, rel, type, metadata, seriesMetadata) {
	const shortSynopsisFirst = rel === "postEpisode" || rel === "preEpisode" || (rel === "main" && ["single", "series"].includes(metadata.type));

	const programTitle = heading ? <h2>{heading}</h2> : getRelatedTitle(seriesMetadata, rel);
	const programSynopsis = rel !== "child" && getSynopsis(metadata, shortSynopsisFirst);
	const programInfo = getProgramInfo(metadata.episodeInfo, type);

	const broadcastTime = getBroadcastTime(metadata, rel, type);

	return programTitle || programSynopsis || programInfo || broadcastTime
		? <div className="information">{programTitle}{broadcastTime}{programSynopsis}{programInfo}</div>
		: null ;
}

function getProgramInfo(episodeInfo, type) {
	return type === "detail" && episodeInfo ? <p>{episodeInfo.minutes} minuter.</p> : null;
}

function getBroadcastTime(metadata, rel, type) {
	const displayBroadcast = metadata._upcomingBroadcast && (rel === "child" || type === "detail");

	return displayBroadcast ? <p className="broadcast-time">{metadata._upcomingBroadcast}</p> : null;
}