import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'

import Core         from '../core/index'
import Login        from '../core/authentication/login'
// import Unauthorized from '../core/authentication/unauthorized'
import ForgotPassword from '../core/authentication/forgotpassword'
import ResetPassword from '../core/authentication/resetpassword'
import PageNotFound from '../core/authentication/pagenotfound'

// AUTHENTICATION
import RequireAuthentication from '../core/authentication/'

// MODULES
import InternalScheduleModule from '../apps/internal-schedule/module'
import InternalScheduleUnauthorized from '../apps/internal-schedule/unauthorized'

const routeConfig = (
	<Route component={Core}>
        <Route renderInAuthForm={true}>
			<Route path="login" component={Login} />
			<Route path="logout" component={Login} />
			<Route path="forgotpassword" component={ForgotPassword} />
			<Route path="resetpassword/:guid" component={ResetPassword} />
		</Route>
		<Route path="unauthorized"component={InternalScheduleUnauthorized} />

        <Route component={RequireAuthentication}>
            <Redirect from="/" to="/schedule/today" />
            { InternalScheduleModule.routes() }
		</Route>
		
		<Route path="*" component={PageNotFound} />
	</Route>
);

export default routeConfig;