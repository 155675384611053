import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'
import moment from 'moment'

import { Filter, Left } from '../../../components/filter'
import DropDown from '../../../components/ui/controls/dropdown'

import ProgramSearch from '../shared/search'
import { logoutUser } from '../../../core/services/auth'

import { getSwedishVersion } from '../../../components/program/utils'

import appConfig from 'config'

export default ({
	title,
	id,
	goBackInsteadOfLink,
	seasonId,
	seasons = [],
	handleSelectSeason,
	programType,
}) => {

	// We need to go back instead of doing a normal navigation to allow for
	// react-router-scroll to restore the scroll position of the library list.
	function handleClick(e) {
		if (goBackInsteadOfLink) {
			e.preventDefault();
			browserHistory.goBack();
		}
	}

	// eslint-disable-next-line no-undef
	const isAuthVersion = __BUILD_PRODUCT__ === "internal-schedule-auth";

	return (
		<Filter>
			<Left>
				<Link
					to={"/schedule"}
					onClick={handleClick}
					className="c6 c6-button c6-text icon-chevron_left"
				>
					<span>Till tablån</span>
				</Link>
				{getMetadataEditorButton(seasonId, id)}
				<div>
					<h1>
						{title}
						{programType && programType !== "single" && (
							<DropDown
								name="seasons"
								onChange={handleSelectSeason}
								states={getSeasons(seasons)}
								currentState={seasonId}
							/>
						)}
					</h1>
				</div>
			</Left>
			{appConfig.features && appConfig.features.internalScheduleSearch && (
				<ProgramSearch extraRightMarginForAuthVersion={isAuthVersion} replaceHistoryOnSelect={true} />
			)}
			{isAuthVersion && (
				<span title="Log out" className="icon-cancel logout" onClick={logoutUser}></span>
			)}
		</Filter>
	);
};

// HELPER
function getSeasons(seriesSeasons) {
	const filteredSeasons = seriesSeasons.filter(isActiveOrUpcomingSeason);
	return filteredSeasons.map(season => {
		const  { id, versions = [], seasonInfo } = season;
		const seasonTitle = getSwedishVersion(versions).title;
		const  { productionYear, seasonNumber } = seasonInfo;

		return {
			key: id,
			text: seasonTitle || (productionYear || seasonNumber ? `SÄSONG ${productionYear || seasonNumber} SAKNAR Season Label` : "?"),
		};
	});
}

function isActiveOrUpcomingSeason(season) {
	const now = moment();
	const isOrWasActive = season.rights?.some(r => moment(r.validFrom).isBefore(now));
	// const isActive = season.rights?.some(r => moment(r.validFrom).isBefore(now) && moment(r.validUntil).isAfter(now));
	const isUpcoming = season.rights?.some(r => moment(r.validFrom).isAfter(now) && r.validFrom.slice(0, 4) !== "2099");
	return isOrWasActive || isUpcoming;
}

function getMetadataEditorButton(seasonId, id) {
	let metadataEditorUrl = appConfig.features && appConfig.features.metadataUrl;
	if (!metadataEditorUrl) {
		return null;
	}

	if (seasonId) {
		metadataEditorUrl += `programs/season/${seasonId}`;
	}
	else {
		metadataEditorUrl += `programs/${id}`;
	}

	return (
		<Link
			target="_blank"
			to={metadataEditorUrl}
			className="c6 c6-button c6-text icon-create icon-smaller"
		>
			<span>Editera i Comet</span>
		</Link>
	);
}