import React from 'react'
import { browserHistory } from 'browserHistory'
import { withRouter } from 'react-router'
// import { ScrollContainer } from 'react-router-scroll';
// import Moment from 'moment'
// import keydown from 'react-keydown'
// import debounce from 'lodash/debounce'

// import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
// import Main from '../../../components/ui/main'

import Header from './header'
import Program from '../../../components/program/program'

import Actions from '../actions'
import Store from '../store'

import Moment from 'moment'

import { getProgramLink } from '../../../components/program/utils'
import { refreshPageIfLostVPNConnection } from '../shared/refreshPageIfLostVPNConnection'

import './app.css'

// @keydown
@withRouter
export default class ProgramApp extends React.Component {

	constructor(props) {
		super(props);

		this.list = null;
		this.id = this.props.params.id;
		this.seriesId = this.props.params.seriesId;
		this.seasonId = this.props.params.seasonId;
		this.broadcastProgramReference = null;

		this.state = {
			...Store.getState(),
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		this.fetchData();
		refreshPageIfLostVPNConnection();
	}

	UNSAFE_componentWillReceiveProps({ params }) {
		const currentSeason = getSeason(this.seasonId, this.state.seasons);

		// Fetch fresh data if the season has changed or we have a single program change
		const seriesChanged = params.seriesId && params.seriesId !== this.seriesId;
		const seasonChanged = params.seasonId && params.seasonId !== (this.seasonId || currentSeason?.id);
		const programChanged = params.id && params.id !== this.id;

		this.seriesId = params.seriesId;
		this.seasonId = params.seasonId;
		this.id = params.id;

		if ((seriesChanged || seasonChanged) || (!this.seriesId && !this.seasonId && programChanged)) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	fetchData = () => {
		const { apikey } = this.state.filters;

		if(this.seriesId && !this.seasonId) { // From the program search we only get the seriesId so we must fetch the seasons as well as the season and episodes for it
			const fetchSeasonBroadcasts = (seasonId) => this.fetchProgramBroadcastsData(apikey, null, seasonId);
			Actions.fetchSeriesAndLatestSeasonWithEpisodes(this.seriesId, { apikey }, fetchSeasonBroadcasts);
			Actions.fetchProgram(this.seriesId, { apikey }); // Just to get the series local title
		}
		else if(this.seriesId) {
			Actions.fetchSeasons(this.seriesId, { apikey });
			Actions.fetchEpisodes(this.seasonId, { apikey });
			Actions.fetchProgram(this.seriesId, { apikey }); // Just to get the series local title
		}
		else if(this.seasonId) {
			// console.log("Only got SeasonID. Will have to fetch series.");
			Actions.fetchSeason(this.seasonId, { apikey });
			// Actions.fetchSeasonAndSeries(this.seasonId, { apikey }); // Just to get the series local title
			Actions.fetchEpisodes(this.seasonId, { apikey });
		}
		else {
			Actions.fetchProgram(this.id, { apikey });
			Actions.fetchEpisodes(this.seasonId, { apikey });
		}

		if (this.id || this.seasonId) {
			this.fetchProgramBroadcastsData(apikey, this.id, this.seasonId);
		}
	}

	fetchProgramBroadcastsData = (apiKey, id, seasonId) => {
		if (id) {
			const now = Moment().format("YYYY-MM-DD");
			const payload = {
				from: now,
				programReference: id,
				apikey: apiKey
			};
			Actions.fetchEpgProgramBroadcasts(payload);
		}

		if (seasonId) {
			const startOfWeek = Moment().startOf("isoWeek").format("YYYY-MM-DD");
			const seasonPayload = {
				from: startOfWeek,
				programReference: seasonId,
				referenceType: "season",
				apikey: apiKey
			};
			Actions.fetchEpgSeasonBroadcasts(seasonPayload);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	onSelectSeason = (e, item) => {
		const seasonId = item && item.props && item.props.value || e.target.value;
		browserHistory.replace(getProgramLink(this.seriesId, seasonId));
	}

	handleSelectEpisode = (seriesId, seasonId, episodeId) => {
		browserHistory.replace({
			pathname: getProgramLink(seriesId, seasonId, episodeId),
			query: this.props.location.query
		});
		this.fetchProgramBroadcastsData(this.state.filters.apikey, episodeId, seasonId);
	}

	render() {
		const {
			isLoading,
			program,
			programBroadcasts,
			seasonBroadcasts,
			seasons,
			episodes,
			schedules,
			channels,
			seriesNid,
		} = this.state;
		const season = getSeason(this.seasonId, seasons);

		let title = "";
		const series = season?.seasonInfo?.seriesId === program?.id
			? program
			: null;
		
		if (series) {
			title = series.versions?.[0]?.title || series.originalTitle || season?.originalTitle || season?.name || "";
		} else if (season) {
			title = season?.originalTitle || season?.name || "";
		} else if (program) {
			title = program.versions?.[0]?.title || program.originalTitle || program.name || "";
		}

		const selectedSeasonId = !this.seasonId && program.type === "season"
			? program.id
			: this.seasonId;

		return (
			<App name={`c6-internal-schedule c6-internal-schedule-program`} layout="grid" isLoading={isLoading}>
				<Header
					title={title}
					goBackInsteadOfLink={!!schedules.length}
					id={this.id}
					seasonId={selectedSeasonId}
					seasons={seasons}
					handleSelectSeason={this.onSelectSeason}
					programType={program?.type}
				/>
				<Program
					program={program}
					programBroadcasts={programBroadcasts}
					seasonBroadcasts={seasonBroadcasts}
					channels={channels}
					season={season}
					episodes={episodes}
					id={this.id}
					seasonId={selectedSeasonId}
					onSelectEpisode={this.handleSelectEpisode}
					seriesId={this.seriesId}
					programNid={program.seriesInfo?.nid || program.singleInfo?.nid || seriesNid}
					isLoading={isLoading}
				/>
			</App>
		);
	}
}

// HELPERS
function getSeason(seasonId, seasons) {
	if (seasonId && seasons.length) {
		const currentSeason = seasons.filter(s => s.id === seasonId);
		return currentSeason.length ? currentSeason[0] : null;
	}
	
	if (seasons.length) {
		return seasons[seasons.length - 1];
	}

	return null;
}