import React, { PureComponent } from 'react'
import { UICenter } from '../../components/ui/alignment.jsx'

import appConfig from 'config'

export default class App extends React.PureComponent {
	render() {
		const { children } = this.props;

		let environmentName;
		switch(appConfig.env) {
			case "Staging":
				environmentName = <span>Bonnier Broadcasting Interntablå&nbsp;<mark>Staging</mark>!</span>;
				break;
			case "Development":
				environmentName = <span>Bonnier Broadcasting Interntablå&nbsp;<mark>Dev</mark>!</span>;
				break;
			default:
				environmentName = "Bonnier Broadcasting Interntablå!";
		}

		const fallback = (
			<div className="c6-welcome">
				<UICenter>
					<h1>Välkommen till &nbsp;{environmentName}</h1>
					<p>Ha en bra dag!</p>
				</UICenter>
			</div>
		);

		return children ? children : fallback;
	}
}