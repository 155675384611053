import appConfig from 'config'

let interval = null;

/**
 * This will reload the page if the response from checkVPNUrl is HTML.
 * It is kind of hacky and probably only works for TV4's setup of Interntablån.
 * 
 * If a user requests any resource from interntabla.a2d.tv when they are not connected to TV4's network,
 * they will receive index.html instead of the requested resource. We can use that to kick the user from
 * Interntablån (after the window reloads, the user will see the "Laddar tablån..." page).
 * 
 * This behavior was requested by JOL & TV4 since they are super strict about not being able to use Interntablån
 * when not on TV4's network.
 */
export function refreshPageIfLostVPNConnection() {
    if (!appConfig.features.checkVPNUrl?.length || interval !== null) {
        return;
    }

    interval = setInterval(() => {
        try {
            fetch(appConfig.features.checkVPNUrl)
                .then(res => {
                    const contentType = res.headers.get("content-type");
                    if (contentType === "text/html") {
                        res.text().then(html => {
                            if (html.includes("<html")) {
                                window.location.reload();
                            }
                        });
                    }
                });
        } catch {}
    }, 60 * 1000);
}
