import React from 'react'
import PropTypes from 'prop-types'
// import Moment from 'moment'

import Main from '../ui/main'
import ResponsiveImage from '../assets/responsiveImage'

// import moment from 'moment-timezone'

// import Const from '../../../core/constants'
import { getSynopsis, Availability, FutureAvailability, getProgramUpcomingBroadcasts, getSeasonPremieres } from './utils'

import Metadata from './metadata'
import PressImages from './pressImages'

import './program.css'

export default class Program extends React.PureComponent {

	static propTypes = {
		program: PropTypes.object,
		programBroadcasts: PropTypes.array,
		channels: PropTypes.array,
		season: PropTypes.object,
		episodes: PropTypes.array,
		onSelectEpisode: PropTypes.func.isRequired,
		id: PropTypes.string,
		programNid: PropTypes.string,
		seasonId: PropTypes.string,
		seriesId: PropTypes.string,
		isLoading: PropTypes.bool,
	}

	render() {
		const {
			id,
			seasonId,
			seriesId,
			program,
			programBroadcasts,
			seasonBroadcasts,
			channels,
			season,
			episodes,
			isLoading,
			onSelectEpisode,
			programNid,
		} = this.props;

		if (isLoading) {
			return null;
		}
		
		if (!(program.id || season && season.id)) {
			return null;
		}

		const classNames = season && season.id ? "c6-program program-season" : "c6-program";

		const currentEpisode = episodes && episodes.length ? episodes.filter((e, i) => id ? e.id === id : i === 0)[0] : null;
		// const currentEpisode = episodes && episodes.length ? episodes.filter(e => e.id === id)[0] : null;
		
		const firstArea = season
			? <SeasonPart
				program={season}
				episodes={episodes}
				seasonBroadcasts={seasonBroadcasts}
				onSelectEpisode={onSelectEpisode}
				seriesId={seriesId}
				seasonId={seasonId}
				id={id || currentEpisode && currentEpisode.id}
			/>
			: <ProgramPart
				program={program}
				programBroadcasts={programBroadcasts}
				seasonBroadcasts={seasonBroadcasts}
				channels={channels}
				programNid={programNid}
			/>;

		let secondArea = null;
		if (currentEpisode) {
			secondArea = (
				<ProgramPart
					program={currentEpisode}
					seasonBroadcasts={seasonBroadcasts}
					channels={channels}
					programNid={programNid}
				/>
			);
		}
		else if (program.type === "single") {
			secondArea = <Metadata program={program} />;
		}

		return (
			<div className={classNames}>
				<Main>{firstArea}</Main>
				<Main>{secondArea}</Main>
				<Main>
					<PressImages
						main={season || program}
						episode={currentEpisode}
						allowDownload={true}
						allowPreview={true}
					/>
				</Main>
			</div>
		);
	}
}

// When rendering single programs or episodes
const ProgramPart = ({ program, programBroadcasts, channels, programNid, seasonBroadcasts }) => {
	const episodeNumber = program?.episodeInfo?.episodeNumber;

	return (
		<div className="section">
			{getMainResponsiveImage(program)}
			{episodeNumber && <h2>Episod {program.episodeInfo.episodeNumber}</h2>}
			{getSynopsis(program)}
			<Availability program={program} programNid={programNid} />
			<FutureAvailability program={program} programNid={programNid} />
			{getProgramUpcomingBroadcasts(programBroadcasts, channels, program, seasonBroadcasts)}
		</div>
	);
};

// When rendering seasons
const SeasonPart = ({ program, episodes, seasonBroadcasts, onSelectEpisode, seriesId, seasonId, id }) => {
	const seasonPremieres = getSeasonPremieres(seasonBroadcasts);
	const headingClass = seasonPremieres ? "has-premieres-list" : "";
	return (
		<div className="section">
			{getMainResponsiveImage(program)}
			<h2 className={headingClass}>Säsong {program.seasonInfo.seasonNumber || "?"} ({program.seasonInfo.numberOfEpisodes || "?"} episoder)</h2>
			{seasonPremieres}
			{getSynopsis(program)}
			{/* {getPlatform(program)} */}
			{getEpisodes(episodes, id, seasonId, seriesId, onSelectEpisode)}
		</div>
	);
};

// HELPERS
function getMainResponsiveImage(program) {
	const programImage = program && program.assets && program.assets.filter(a => a.category === "main" && a.type === "image");
	const image = programImage && programImage.length ? programImage[0] : null;

	return image ? <ResponsiveImage id={image.id} sizes="(min-width: 985px) 33vw, (min-width: 665px) 50vw, 100vw" /> : null;
}

function getEpisodes(episodes = [], episodeId, seasonId, seriesId, onSelectEpisode) {
	return episodes.map(episode => {
		const { id, versions, episodeInfo } = episode;
		let /*synopsis = "",*/ shortSynopsis = "";
		if (versions?.length) {
			const { extended, long, medium, brief, short } = versions[0].synopsis;
			// synopsis = extended || long || medium || brief || short || <em>Ingen episodsynopsis.</em>;
			shortSynopsis = short || brief || medium || long || extended;
		}

		const className = id === episodeId ? "sel" : null

		return (
			<article key={id} className={className} onClick={() => {onSelectEpisode(seriesId, seasonId, id);}}>
				<div>{episodeInfo.episodeNumber}</div>
				<p>{shortSynopsis || <em>Ingen episodsynopsis.</em>}</p>
				{/* {correctEpisodeDate} */}
			</article>
		);
	});
}

// // Whether it's a premiere, an ongoing or non existent
// function getCorrectEpisodeDate(episode) {
// 	const expirationDate = getActiveSvodRights(episode)?.map(r => r.validUntil).filter(distinct);
// 	const availableDate = getFutureSvodRights(episode)?.map(r => r.validFrom).filter(distinct);

// 	if (!expirationDate && !availableDate) return <p>N/A</p>;
// 	// Checking [0] because the below method might find multiple dates that match, so taking first
// 	if (expirationDate?.length !== 0) return <p>Tillgänglig till {moment(expirationDate[0]).format("YYYY-MM-DD")}</p>;
// 	if (availableDate?.length !== 0) return <p>Tillgänglig från {moment(availableDate[0]).format("YYYY-MM-DD")}</p>;
// }

// function distinct(value, index, self) {
// 	return self.indexOf(value) === index;
// }
