import React from 'react'
import Moment from 'moment'
import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'

import Const from '../../../core/constants'

import App from '../../../components/app'
import Main from '../../../components/ui/main'

import Empty from '../../../components/list/empty'

import Header from './header'
import Schedule from './schedule'
import Broadcast from './broadcast'

import Actions from '../actions'
import Store from '../store'
import { refreshPageIfLostVPNConnection } from '../shared/refreshPageIfLostVPNConnection'

import './app.css'
import '../shared/channels.css'

const LS_KEY = "internalschedule-selectedchannels";

import appConfig from 'config'


@keydown("left", "right", "ctrl+t")
export default class ScheduleApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFetchSchedule = this.onFetchSchedule.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.list = null;
		this.date = this.props.params.date || Moment().format(Const.DATE_FORMAT);
		this.channels = this.props.params.channels || localStorage.getItem(LS_KEY) || null;

		this.refreshTimerId = null;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (!this.state.schedules.length) {

			// Force localstorage removal of hidden, old or renamed channel keys
			const filteredChannels = this.getFilteredChannels(this.channels, true);
			this.channels = filteredChannels;
			localStorage.setItem(LS_KEY, this.channels);
			this.onFetchSchedule();
		}

		window.clearInterval(this.refreshTimerId);
		this.refreshTimerId = window.setInterval(() => {
			const hideLoading = true;
			this.onFetchSchedule(hideLoading);
		}, 5 * 60 * 1000); // Refresh every five minutes

		refreshPageIfLostVPNConnection();
	}

	componentDidUpdate() {
		const { keydown, params } = this.props;
		const newDate = params.date || Moment().format(Const.DATE_FORMAT);
		const newChannels = params.channels || this.state.filters.filter.channel;
		const filteredNewChannels = this.getFilteredChannels(newChannels);
		if (!keydown.event && (newDate !== this.date || filteredNewChannels !== this.channels)) {
			this.date = newDate;
			this.channels = filteredNewChannels;
			localStorage.setItem(LS_KEY, this.channels);

			// We don't want to fetch schedules if no channels are picked
			if (this.channels.length > 0) {
				this.onFetchSchedule();
			}
		}

		// if (keydown.event) {
		// 	console.log( keydown.event.which );
		// 	// this.onSearch(keydown.event);
		// }
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
		window.clearInterval(this.refreshTimerId);
	}

	onChange(state) {
		this.setState(state);
	}

	getFilteredChannels(channels, useDefaultChannelsIfEmpty) {
		const filteredChannels = [];

		this.state.channels.forEach(c => {
			if (
				appConfig.features.hideCMoreChannels && c.group === "cmore"
				|| appConfig.features.hideTV4Channels && c.group === "tv4"
				|| appConfig.features.hideMTVChannels && c.group === "mtv"
				|| appConfig.features.hideFinnishChannels && c.group === "cmore-fi"
			) {
				return;
			}

			if (channels?.split(",").includes(c.key)) {
				filteredChannels.push(c.key);
			}
		});

		if (filteredChannels.length === 0 && useDefaultChannelsIfEmpty) {
			return this.state.filters.filter.channel;
		}

		return filteredChannels.join(",");
	}

	onFetchSchedule(hideLoading) {
		const channelsArr = this.channels.split(",");
		const cmoreEpgChannels = channelsArr.filter(c => (c.includes("cmore") || ["sportkanalen"].includes(c)) && !c.includes("cmore-se"));
		const tv4EpgChannels = channelsArr.filter(c => (c.includes("tv4") || c.includes("mtv") || ["sjuan", "tv12", "fakta", "sf-kanalen"].includes(c)) && !c.includes("tv4play"));
		const tv4PlayChannels = channelsArr.filter(c => c === "tv4play");
		const tv4PlayPlusChannels = channelsArr.filter(c => c === "tv4play-hvod" || c === "tv4play-svod");
		const cmoreVodChannels = channelsArr.filter(c => c === "cmore-se");
		// #TempHackForTv4InternalSchedule
		const epgPayload = cmoreEpgChannels.length ? getSearchPayload(this.state, this.date, cmoreEpgChannels.join(",")) : null;
		const tv4EpgPayload = tv4EpgChannels.length ? getSearchPayload(this.state, this.date, tv4EpgChannels.join(",")) : null;
		const vodPayload = cmoreVodChannels.length ? getSearchPayload(this.state, this.date, null, cmoreVodChannels.join(",")) : null;
		const tv4PlayPayload = tv4PlayChannels.length ? getSearchPayload(this.state, this.date, null, null, "tv4play") : null;
		const tv4PlayPlusPayload = tv4PlayPlusChannels.length ? getSearchPayload(this.state, this.date, null, null, "tv4play,tv4playplus") : null;
		Actions.fetchEpgDay(this.channels, epgPayload, tv4EpgPayload, vodPayload, tv4PlayPayload, tv4PlayPlusPayload);
	}

	onFilter(type, e) {
		if (type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter(type, event) {
		if (this.list) {
			this.list.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		// Actions[type](value, name);
		// Actions.fetchItems(DATASTORE, getSearchPayload(this.state));

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { isLoading, schedules, channels, filters, vodPackages, tv4PlayPackages, tv4PlayPlusPackages } = this.state;
		const date = this.date && Moment(this.date) || null;

		return (
			<App name="c6-internal-schedule c6-list" layout="grid" isLoading={isLoading}>
				<Header
					keydown={this.props.keydown}
					date={date && date.format(Const.DATE_FORMAT)}
					selectedChannels={filters.filter.channel}
					channels={channels}
				/>
				<ScrollContainer scrollKey="c6-internal-schedule-day" shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main>
						{filters.filter.channel?.length > 0
							? renderSchedule(filters.filter.channel, this.date, schedules, channels, vodPackages, tv4PlayPackages, tv4PlayPlusPackages)
							: renderEmpty()}
					</Main>
				</ScrollContainer>
			</App>
		);
	}
}

// HELPERS
function getSearchPayload({ filters = {} }, day, epgChannels, vodChannels, sites) {
	const { filter, ...rest } = filters;
	const payload = {
		...rest,
		...filter,
	};

	if (day) {
		payload.day = day;
	}
	if (epgChannels) {
		payload.channel = epgChannels;
		payload.sites = null;
	}
	if (vodChannels) {
		payload.channel = vodChannels;
		payload.sites = null;
	}
	if (sites) {
		payload.channel = null;
		payload.sites = sites;
	}


	return payload;
}

function renderSchedule(filtersChannel, date, schedules, channels, vodPackages, tv4PlayPackages, tv4PlayPlusPackages) {
	if (channels.length > 0) {
		return (
			<Schedule
				selectedChannels={filtersChannel}
				day={date}
				schedules={schedules}
				channels={channels}
				vodPackages={vodPackages}
				tv4PlayPackages={tv4PlayPackages}
				tv4PlayPlusPackages={tv4PlayPlusPackages}
			>
				<Broadcast />
			</Schedule>
		);
	}
}

function renderEmpty() {
	return (
		<Empty v2={true}>
			Why not pick a channel from the list above?
		</Empty>
	);
}