import alt from '../../core/services/alt'
import Actions from './actions'

import { addChannel, removeChannel } from './shared/utils'

import appConfig from 'config'

class InternalScheduleStore {

	constructor() {
		this.isLoading = false;
		this.latestRequestTime = null;

		this.programBroadcasts = [];
		this.seasonBroadcasts = [];
		this.seriesNid = null;
		this.schedules = [];
		this.vodPackages = [];
		// #TempHackForTv4InternalSchedule
		this.tv4PlayPackages = [];
		this.tv4PlayPlusPackages = [];
		this.program = {};
		this.seasons = [];
		this.episodes = [];
		this.family = {};
		this.channels = [];

		if (!appConfig.features.hideTV4Channels) {
			this.channels.push(
				{ group: "tv4", key: "tv4", text: "TV4" },
				{ group: "tv4", key: "sjuan", text: "Sjuan" },
				{ group: "tv4", key: "tv12", text: "TV12" },
				{ group: "tv4", key: "fakta", text: "TV4 Fakta" },
				{ group: "tv4", key: "tv4-guld", text: "TV4 Guld" },
				{ group: "tv4", key: "tv4-film", text: "TV4 Film" },
				{ group: "tv4", key: "tv4-hits-se", text: "TV4 Hits SE" },
				{ group: "tv4", key: "tv4-stars-se", text: "TV4 Stars SE" },
				{ group: "tv4", key: "sf-kanalen", text: "SF-kanalen" },
				{ group: "tv4", key: "tv4-fotboll", text: "TV4 Fotboll" },
				{ group: "tv4", key: "tv4-hockey", text: "TV4 Hockey" },
				{ group: "tv4", key: "tv4-tennis", text: "TV4 Tennis" },
				{ group: "tv4", key: "tv4-motor", text: "TV4 Motor" },
				{ group: "tv4", key: "tv4-sportkanalen", text: "TV4 Sportkanalen" },
				{ group: "tv4", key: "tv4-sport-live-1", text: "TV4 Sport Live 1" },
				{ group: "tv4", key: "tv4-sport-live-2", text: "TV4 Sport Live 2" },
				{ group: "tv4", key: "tv4-sport-live-3", text: "TV4 Sport Live 3" },
				{ group: "tv4", key: "tv4-sport-live-4", text: "TV4 Sport Live 4" },
			);
		}
		
		if (!appConfig.features.hideCMoreChannels) {
			this.channels.push(
				{ group: "cmore", key: "cmore-first", text: "C More First" },
				{ group: "cmore", key: "sportkanalen", text: "Sportkanalen" },
				{ group: "cmore", key: "sf-kanalen", text: "SF-kanalen" },
				{ group: "cmore", key: "cmore-hits", text: "C More Hits" },
				{ group: "cmore", key: "cmore-stars", text: "C More Stars" },
				{ group: "cmore", key: "cmore-series-hd", text: "C More Series" },
				{ group: "cmore", key: "cmore-mix", text: "C More Mix" },
				{ group: "cmore", key: "cmore-fotboll", text: "C More Fotboll" },
				{ group: "cmore", key: "cmore-hockey", text: "C More Hockey	" },
				{ group: "cmore", key: "cmore-live-hd", text: "C More Live" },
				{ group: "cmore", key: "cmore-live-2", text: "C More Live 2" },
				{ group: "cmore", key: "cmore-live-3", text: "C More Live 3" },
				{ group: "cmore", key: "cmore-live-4", text: "C More Live 4" },
				{ group: "cmore", key: "cmore-live-5", text: "C More Live 5" },
				{ group: "hidden-virtual", key: "cmore-golf-sfkanalen", text: "C More Golf SF-Kanalen" },
				{ group: "hidden-virtual", key: "cmore-fotboll-hockey-stars", text: "C More Fotboll Hockey Stars" },
				{ group: "hidden-virtual", key: "cmore-live-hits", text: "C More Live Hits" },
			);
		}

		if (!appConfig.features.hideMTVChannels) {
			this.channels.push(
				{ group: "mtv", key: "mtv3", text: "MTV3" },
				{ group: "mtv", key: "sub", text: "Sub" },
				{ group: "mtv", key: "ava", text: "AVA" },
				{ group: "mtv", key: "mtv-aitio", text: "MTV Aitio" },
				{ group: "mtv", key: "mtv-juniori", text: "MTV Juniori" },
				{ group: "mtv", key: "mtv-max", text: "MTV MAX" },
				{ group: "mtv", key: "mtv-viihde", text: "MTV Viihde" },
				{ group: "mtv", key: "mtv-urheilu-1", text: "MTV Urheilu 1" },
				{ group: "mtv", key: "mtv-urheilu-2", text: "MTV Urheilu 2" },
				{ group: "mtv", key: "mtv-urheilu-3", text: "MTV Urheilu 3" },
			);
		}

		if (!appConfig.features.hideFinnishChannels) {
			this.channels.push(
				{ group: "cmore-fi", key: "cmore-max-finland", text: "C More Max" },
				{ group: "cmore-fi", key: "cmore-max2-finland", text: "C More Max 2" },
				{ group: "cmore-fi", key: "cmore-sport1-finland", text: "C More Sport 1" },
				{ group: "cmore-fi", key: "cmore-sport2-finland", text: "C More Sport 2" },
				{ group: "cmore-fi", key: "cmore-juniori-finland", text: "C More Juniori" },
			);
		}

		if (appConfig.features.internalScheduleVodViewsEnabled) {
			this.channels.push(
				{ group: "tv4", key: "tv4play", text: "TV4 Play" },
				{ group: "tv4", key: "tv4play-hvod", text: "TV4 Play+ (HVOD)" },
				{ group: "tv4", key: "tv4play-svod", text: "TV4 Play+ (SVOD)" },
			);

			if (!appConfig.features.hideCMoreChannels) {
				this.channels.push({ group: "cmore", key: "cmore-se", text: "CMore.se" });
			} else if (!appConfig.features.hideFinnishChannels) {
				this.channels.push({ group: "cmore-fi", key: "cmore-se", text: "CMore.se" });
			}
		}

		this.filters = {
			filter: {
				channel: this.channels.slice(0, 3).map(c => c.key).join(","),
			},
			apikey: appConfig.api.contentKey || "",
		};
		this._prevFilter = null;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			onNavigation: [Actions.NAV_NEXT, Actions.NAV_PREV, Actions.NAV_TODAY, Actions.NAV_DATE],
			onSelectChannel: Actions.SELECT_CHANNEL,
			onUnselectChannel: Actions.UNSELECT_CHANNEL,

			onFetchEpgDay: Actions.FETCH_EPG_DAY,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onVodPackagesUpdated: Actions.VOD_PACKAGES_UPDATED,
			// #TempHackForTv4InternalSchedule
			onTv4PlayPackagesUpdated: Actions.TV4PLAY_PACKAGES_UPDATED,
			onTv4PlayPlusPackagesUpdated: Actions.TV4PLAY_PLUS_PACKAGES_UPDATED,

			onFetchEpgProgramBroadcasts: Actions.FETCH_EPG_PROGRAM_BROADCASTS,
			onEpgProgramBroadcastsUpdated: Actions.EPG_PROGRAM_BROADCASTS_UPDATED,
			onFetchEpgSeasonBroadcasts: Actions.FETCH_EPG_SEASON_BROADCASTS,
			onEpgSeasonBroadcastsUpdated: Actions.EPG_SEASON_BROADCASTS_UPDATED,

			onFetchProgram: Actions.FETCH_PROGRAM,
			onProgramUpdated: Actions.PROGRAM_UPDATED,
			onFetchSeason: Actions.FETCH_SEASON,
			onFetchSeasons: Actions.FETCH_SEASONS,
			onSeasonsUpdated: Actions.SEASONS_UPDATED,
			onFetchEpisodes: Actions.FETCH_EPISODES,
			onEpisodesUpdated: Actions.EPISODES_UPDATED,
			onFetchFamily: Actions.FETCH_FAMILY,
			onFamilyUpdated: Actions.FAMILY_UPDATED,

			// VOD-VIEW Excel Export
			onExportExcelFinish: Actions.EXPORT_VOD_PACKAGE_EXCEL_FINISH,
			onExportExcel: Actions.EXPORT_VOD_PACKAGE_EXCEL,

			onUnmount: Actions.UNMOUNT,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
	}

	/* Filters */
	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		this.filters.filter = {
			...filterParams,
			...payload,
		};

		this._prevFilter = null;
		this.schedules = [];
	}

	/* EPG */
	onFetchEpgDay({ channels, hideLoading = false }) {
		this.isLoading = !hideLoading;
		this.filters.filter.channel = channels;
		this.latestRequestTime = Date.now();
	}

	onNavigation() {
		// this.isLoading = true;
	}

	onSelectChannel(channelsToAdd) {
		this.filters.filter.channel = addChannel(channelsToAdd, this.filters.filter.channel);
	}

	onUnselectChannel(channelsToRemove) {
		this.filters.filter.channel = removeChannel(channelsToRemove, this.filters.filter.channel);
	}

	onItemsUpdated({ data, requestTime }) {
		if (this.latestRequestTime > requestTime) {
			console.log("Ignoring result since there have been newer requests.");
		}
		else {
			this.schedules = data;
			this.isLoading = false;
		}
	}

	onVodPackagesUpdated({ data }) {
		this.vodPackages = data;
	}
	// #TempHackForTv4InternalSchedule
	onTv4PlayPackagesUpdated({ data }) {
		this.tv4PlayPackages = data;
	}
	onTv4PlayPlusPackagesUpdated({ data }) {
		this.tv4PlayPlusPackages = data;
	}

	/* METADATA */
	onFetchProgram() {
		this.isLoading = true;
		this.seasons = [];
		this.episodes = [];
	}
	onFetchSeason() {
		this.isLoading = true;
	}
	onProgramUpdated(data) {
		this.program = data;
		this.isLoading = false;

		// HACK: Since season data does not include series nid
		// and we overwrite this.program when the latest season is selected,
		// store the series nid here instead
		if (data.type === "series") {
			this.seriesNid = data.seriesInfo?.nid;
		}
	}
	onFetchSeasons() {
		// this.isLoading = true;
	}
	onSeasonsUpdated(data) {
		this.seasons = data;
		// this.isLoading = false;
	}
	onFetchEpisodes() {
		// this.isLoading = true;
	}
	onEpisodesUpdated(data) {
		this.episodes = data;
		// this.isLoading = false;
	}
	onFetchFamily() {
		this.isLoading = true;
	}
	onFamilyUpdated(data) {
		this.family = data;
		reverseEpisodesForCurrentSeason(this.family);
		this.isLoading = false;
	}

	// Broadcasts (program)
	onFetchEpgProgramBroadcasts() {
		this.isLoading = true;
	}
	onEpgProgramBroadcastsUpdated(data) {
		this.programBroadcasts = data;
		this.isLoading = false;
	}
	onFetchEpgSeasonBroadcasts() {
		this.isLoading = true;
	}
	onEpgSeasonBroadcastsUpdated(data) {
		this.seasonBroadcasts = data;
		this.isLoading = false;
	}

	onUnmount() {
		this.isLoading = false;
		this.program = {};
		this.programBroadcasts = [];
		this.seasonBroadcasts = [];
		this.seasons = [];
		this.episodes = [];
		this.family = {};
	}
	
	// VOD-VIEW Excel Export
	onExportExcel() {
		this.isLoading = true;
	}
	
	onExportExcelFinish() {
		this.isLoading = false;
	}
}

export default alt.createStore(InternalScheduleStore, '[InternalSchedule]Store');

function reverseEpisodesForCurrentSeason(family) {
    family.data.links.forEach(series => {
        series.links.forEach(season => {
            if (season.current) {
                season.links = season.links.reverse();
            }
        });
    });
}